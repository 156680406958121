import '@ovotech/ovoenergy-theme/dist/global.css';
import { useEffect, useState } from 'react';

function Leasing() {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
    if (
      window.location.href ===
        'https://evs.ovoenergy.com/electric-vehicle-leasing' ||
      window.location.href ===
        'https://evs-uat.ovoenergy.com/electric-vehicle-leasing' ||
      window.location.href === 'http://localhost:8000/electric-vehicle-leasing/'
    ) {
      window.location.replace(
        'https://www.ovoenergy.com/electric-cars/electric-vehicle-leasing',
      );
    }
  }, []);
  if (!hasMounted) {
    return null;
  }
  return null;
}

export default Leasing;
